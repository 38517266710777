<template>
  <div class="container">
    <h1 class="main-claim">Restauración de suelos</h1>
    <p class="sub-claim">
      Nuestro objetivo principal es restaurar los suelos. Suelo que nutrirá las plantas para que las plantas puedan
      nutrir a los animales y a los humanos. Sin suelo vivo no hay vida en absoluto.
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-12-02_13-19-27.jpg" />
        <figcaption>Nuestro objetivo es ir de esto</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-19_12-21.jpg" />
        <figcaption>a eso y más allá</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Alimentos especiales</h1>
    <p class="sub-claim">
      A medida que nuestro suelo mejora y se vuelve más y más fértil, podemos cosechar el excedente. Dos ejemplos de
      nuestro productos alimenticios especiales:
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-11-11_11-45-16.jpg" />
        <figcaption>Jamón de cerdo ibérico de bellota</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-12-03_13-35-09.jpg" />
        <figcaption>Chile cultivado bajo el cálido sol andaluz</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Animales en un entorno que imita la naturaleza
    </h1>
    <p class="sub-claim">
      En cualquier ecosistema los animales juegan un papel vital. Estudiamos su comportamiento natural y pretendemos
      crear un entorno imitando a la naturaleza lo que les permite expresar plenamente sus instintos.
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-11-24_11-56-04.jpg" />
        <figcaption>Cerdos ibéricos</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-02_11-58-52.jpg" />
        <figcaption>Fauna silvestre</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/cattle.jpg" />
        <figcaption>Ganado mestizo resistente</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-25_20-06-54.jpg" />
        <figcaption>Gatos y otros animales de granja</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Buscamos abundancia y cosechamos el excedente</h1>
    <p class="sub-claim">
      La naturaleza se nutre de la abundancia y por eso los sistemas naturales son más resistentes. Operamos en el
      clima mediterráneo con sequía estival prolongada sin lluvias durante unos 6 meses. Solo le quitamos el
      excedente para que nuestro suelo siempre está protegido del sol inclemente.
    </p>
    <div class="claim-pictures">
      <figure>
        <img src="@/assets/images/landing/2022-04-08_18-54-20.jpg" />
        <figcaption>Una abundancia de hierba</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2022-04-25_20-15-53.jpg" />
        <figcaption>Pastoreo rotativo bien manejado</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/2021-03-31_13-34-30.jpg" />
        <figcaption>Camiones de biomasa durante el verano</figcaption>
      </figure>
      <figure>
        <img src="@/assets/images/landing/horns03.jpg" />
        <figcaption>Animales sanos</figcaption>
      </figure>
    </div>

    <h1 class="main-claim">Pagar por valor</h1>
    <p class="sub-claim">
      La transparencia y la comunicación clara son muy importantes para nosotros. Usted como nuestro cliente podrá
      correctamente determinar el valor de cualquier producto que desee comprar y establezer su propio precio.
    </p>

  </div>
</template>

<script>
export default {
  name: "HomeIndexES",
  components: {},
};
</script>
<style scoped>

</style>
